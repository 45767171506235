<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <WidgetToolbar
        v-if="Object.keys(widget).length !== 0"
        :widget="widget"
        closing
      />
      <hr>
      <div class="table-filters d-flex justify-content-between align-items-center mb-25">
        <div>
          <h4>{{ $t('Statistics') }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div class="col-picker">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="toggleRange"
              />
            </div>
          </div>
          <div v-if="authUserData.role.includes('admin')">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-25"
              :to="{ name: 'dashboard-site-widget-stats-v2' }"
            >
              V2
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <b-table
          small
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(datetime)>
            {{ $t('stats.date') }}
          </template>
          <template #head(show)>
            {{ $t('stats.shows') }}
          </template>
          <template #head(click)>
            {{ $t('stats.clicks') }}
          </template>
          <template #head(articles)>
            {{ $t('stats.clicks_article') }}
          </template>
          <template #head(exchange)>
            {{ $t('stats.clicks_exchange') }}
          </template>
          <template #head(advertising)>
            {{ $t('stats.clicks_creative') }}
          </template>
          <template #head(amount)>
            {{ $t('stats.amount') }}
          </template>
          <template #cell(datetime)="data">
            {{ typeStats === 'day' ? getFormattedDateTime(new Date(data.value)) : getFormattedDate(new Date(data.value)) }}
          </template>
          <template #cell(amount)="data">
            {{ data.value ? currency(account.currency, data.value) : 0 }}
          </template>
          <template #cell(show)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>
          <template #cell(click)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>
          <template #cell(articles)="data">
            {{ data.item.stats.default.article ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.article) : 0 }}
          </template>
          <template #cell(exchange)="data">
            {{ data.item.stats.default.exchange ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.exchange) : 0 }}
          </template>
          <template #cell(advertising)="data">
            {{ data.item.stats.default.creative ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.item.stats.default.creative) : 0 }}
          </template>
          <template #cell(ctr)="data">
            {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.click / data.item.show * 100) }}%
          </template>
          <template #cell(ecpm)="data">
            {{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(data.item.amount / data.item.show * 1000) }}
          </template>
          <template #foot(datetime)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          </template>
          <template #foot(click)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('click')) }}</strong>
          </template>
          <template #foot(show)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('show')) }}</strong>
          </template>
          <template #foot(articles)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalArticles) }}</strong>
          </template>
          <template #foot(amount)="data">
            <strong :data-label="data.label">{{ currency(account.currency, totalAmount) }}</strong>
          </template>
          <template #foot(exchange)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalExchange) }}</strong>
          </template>
          <template #foot(advertising)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalAdvertising) }}</strong>
          </template>
          <template #foot(ctr)="data">
            <strong :data-label="data.label">{{ totalFormat('show') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 4 }).format(totalFormat('click') / totalFormat('show') * 100) : 0 }}%</strong>
          </template>
          <template #foot(ecpm)="data">
            <strong :data-label="data.label">{{ totalFormat('show') ? new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 2 }).format(totalAmount / totalFormat('show') * 1000) : 0 }}</strong>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import {
  BCard,
  BButton,
  BTable,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import WidgetToolbar from '@/views/dashboard/widgets/WidgetToolbar.vue'
import flatPickr from 'vue-flatpickr-component'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'

export default {
  components: {
    Breadcrumbs,
    BButton,
    BCard,
    BTable,
    flatPickr,
    BSpinner,
    UserToolbar,
    WidgetToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      authUserData: getUserData(),
      loading: true,
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: 'custom',
      site: [],
      rows: [],
      widget: {},
      fields: [
        { key: 'datetime', label: 'Date' },
        { key: 'show', label: 'Impressions' },
        { key: 'click', label: 'Clicks' },
        { key: 'articles', label: 'My articles' },
        { key: 'exchange', label: 'Exchange' },
        { key: 'advertising', label: 'Advertising' },
        { key: 'ctr', label: 'CTR' },
        { key: 'ecpm', label: 'eCPM' },
        { key: 'amount', label: 'Income' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      rangePicker: [getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), getFormattedDate(new Date())],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
    totalArticles() {
      let totalArticles = 0
      this.rows.forEach(i => {
        totalArticles += i.stats.default.article
      })
      return totalArticles
    },
    totalExchange() {
      let total = 0
      this.rows.forEach(i => {
        total += i.stats.default.exchange
      })
      return total
    },
    totalAdvertising() {
      let total = 0
      this.rows.forEach(i => {
        total += i.stats.default.creative
      })
      return total
    },
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
  },
  created() {
    this.getUserData()
    this.getSiteData()
    this.getWidgetData()
    this.getWidgetStatsByType(true)
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'Sites',
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          to: `/users/${this.site.user_id}/sites/${this.site._id}`,
        },
        {
          text: this.$t('page.features.widgets'),
          to: `/users/${this.site.user_id}/sites/${this.site._id}/widgets/`,
        },
        {
          text: this.widget.title,
          active: true,
        },
      ]
    },
    async getWidgetData() {
      const responseData = await useJwt.getWidget(this.$route.params.widgetId)
      this.widget = responseData.data.widget
      this.setBreabcrumbs()
    },
    toggleRange(e) {
      this.rangePicker = e
      this.getWidgetStatsByType()
    },
    async getWidgetStatsByType(start = false) {
      this.loading = true
      let { from: dateFrom, to: dateTo } = this.selectedRangeFormated
      this.rows = null

      if (start) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const urlParams = Object.fromEntries(urlSearchParams.entries())

        if (urlParams.type) {
          this.typeStats = urlParams.type
        }

        if (urlParams.from) {
          dateFrom = urlParams.from
        }

        if (urlParams.to) {
          dateTo = urlParams.to
        }

        this.rangePicker = [dateFrom, dateTo]
      }

      this.$router.replace({
        name: 'dashboard-site-widget-stats',
        query: { type: this.typeStats, from: dateFrom, to: dateTo },
      }).catch(() => {})

      const params = {
        from: dateFrom,
        site_id: this.$route.params.siteId,
        widget_id: this.$route.params.widgetId,
        to: dateTo,
        type: this.typeStats,
      }
      const responseData = await useJwt.getWidgetStatsByType(params)
      this.rows = responseData.data.widgetstats || []
      this.loading = false
    },
    changeType() {
      this.$router.replace({
        name: 'dashboard-site-widget-stats',
        query: { type: this.typeStats },
      })
      this.getWidgetStatsByType()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
